<template>
  <div>
    <section class="hero-text overflow-hidden hero-text--dense">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h1 class="ml-0">
              {{ $t('supported_languages') }}
            </h1>
            <div class="subtitle">
              {{ $t('supported_languages_last_updated') }}: July 1, 2021
            </div>
          </div>
        </div>
      </div>
    </section>
    <section style="padding-bottom: 0;">
      <div class="container">
        <div>
          <h3 class="mt-0 mb-5">
            {{ $t('natively_supported') }}
          </h3>
        </div>
        <div class="columns pt-2">
          <div class="column col-3 col-md-6" v-for="(lang, index) in uploadLanguages" :key="lang.code + index">
            <div class="d-flex align-center supported-language">
              <img class="mr-2" :src="getLanguageFlag(lang.code)">
              <div class="ml-2">
                {{ lang.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div>
          <h3 class="mt-0 mb-5">
            {{ $t('translation_supported') }}
          </h3>
        </div>
        <div class="columns pt-2">
          <div class="column col-3 col-md-6" v-for="(lang, index) in translateLanguages" :key="lang.code + index">
            <div class="d-flex align-center supported-language">
              <!-- <img class="mr-2" :src="getLanguageFlag(lang.code)"> -->
              <div class="ml-2">
                {{ lang.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

export default {
  data () {
    return {
      languageOptions: {
        iso: {},
        caplena_supported: [],
        google_translate: []
      }
    }
  },

  computed: {
    uploadLanguages () {
      return _.map(this.languageOptions.caplena_supported, v => ({
        name: this.languageOptions.iso[v],
        code: v
      })).sort()
    },
    translateLanguages () {
      return _.map(this.languageOptions.google_translate, v => ({
        name: this.languageOptions.iso[v],
        code: v
      }))
    }
  },

  watch: {
    // Whenever the locale changes, update the language options form the API
    '$i18n.locale': 'getLanguageOptions'
  },

  created () {
    this.getLanguageOptions()
  },

  methods: {
    /**
     * Get the language options, such as supported languages and mappings of ISO codes
     * to human-readable versions, from the API
     */
    getLanguageOptions () {
      // Be specific as to which language we request, as the user-settings would tak
      // precedence here otherwise
      this.$api.get(`/api/constants/language-options?language=${this.$i18n.locale}`).then(res => {
        console.log(res.data)
        this.$set(this, 'languageOptions', res.data)
      })
    },

    getLanguageFlag (code) {
      return require(`@/assets/landing/flags/${code}.svg`)
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/landing/SupportedLanguages.json' />
<i18n locale='de' src='@/i18n/de/landing/SupportedLanguages.json' />